.grid-item-responsive{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-responsive{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: '16.18px';
  line-height: '20px';
  text-align: 'center';
  padding: '3%';
}

.card-subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: '16.18px';
  line-height: '20px';
}

@media screen and (min-width: 768px){
  .card-responsive{
    width: 60%;
    padding: 5px;
  }
  .card-subtitle {
    text-align: left;
  }
}
