.card-centered{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.text-left{
  text-align: left;
}
.height-full{
  height: 100%;
}
.main-container {
  margin-top: 5rem;
}