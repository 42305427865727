.margin-y{
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.grid-height{
  height: 9rem;
}

.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}
.title{
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16.18px;
  line-height: 20px;
  color: #145374;
}

.inter{
  font-family: 'Inter';
}
.modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  background-color: #fff;
  box-shadow: 24;
  padding: 1rem;
  border-radius: 2rem;
}

.text-center{
  text-align: center;
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.swal2-container {
  z-index: 10000;
}

@media screen and (min-width: 768px) {
  .modal-box {
    width: 50%;
  }
}