.card{
  background-color: #FAFAFA;
}

.text-left {
  text-align: left;
}

.text-bottom {
  position: relative;
  bottom: 0;
}

.text-right {
  text-align: right;
}

.align-right {
  display: flex;
  align-items: center;
  align-self: self-end;
  justify-content: flex-end;
  width: 100%;
}

.text-title {
  font-family: "Inter";
  font-size: 16.18px;
  font-weight: 300;
  line-height: 5px;
}

.text-content {
  font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
  line-height: 5px;
}

.text-footer-in {
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
}

.text-footer-out {
  font-family: "Inter";
  font-weight: 700;
  color: #5588A3;
  text-decoration: "none";
  font-size: 14px;
}
