@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@layer components {
  .subtitle-1{
    @apply font-inter font-bold text-3xl text-primary text-center md:text-left;
  }
  .subtitle-2{
    @apply font-inter font-bold text-3xl text-primary text-center;
  }
  .subtitle-3{
    @apply text-3xl text-center mt-4 text-primary;
  }
  .subtitle-4{
    @apply font-inter font-bold text-base text-primary text-center;
  }
  .subtitle-mini{
    @apply text-xs text-left text-gray-400 uppercase font-bold py-2;
  }
  .list-text-responsive{
    @apply text-sm md:text-base
  }
  .form-field{
    outline: none;
    width: 100%;
    @apply p-4 rounded-lg h-14 border-2 border-field
  }
}
