.people-container {
  height: 50rem;
}

.margin-y{
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.grid-height{
  /*height: 25rem;*/
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .people-container {
    height: auto;
  }
}