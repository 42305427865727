.secondary-button {
  background: linear-gradient(120deg, rgb(50,50,80) 30%, rgba(0,51,78) 100%);
  margin-bottom: 20px;
  width: 100%;
  margin-top: 5px;
  border-radius: 6px;
}

.button-disabled {
  background-color: darkgrey;
  margin-bottom: 20px;
  width: 100%;
  margin-top: 5%;
  border-radius: 6;
}