.main-container {
  height: 100%;
}

.main-card {
  border: "1px solid #D9D9D9";
  display: flex;
  padding: 4px;
  margin-bottom: 4%;
  border-radius: 4px;
}

.margin-5 {
  margin-top: 14%;
}

.image-card-detail {
  border: "1px solid #5588A3";
  border-radius: "4px";
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: #145374;
  text-align: left;
}

.info-text {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.5rem;
  text-align: left;
  padding: 0;
}

.divider {
  display: none;
  padding-left: 6px;
  padding-right: 6px;
}

@media screen and (min-width: 405px) {
  .info-text {
    padding: 0.1rem 0;
  }
}

@media screen and (min-width: 768px) {
  /*.margin-responsive {
    margin-bottom: -25% !important;
  }*/
  /*.grid-responsive{
    height: 50%;
  }*/
  .info-text {
    font-size: 0.6rem;
    line-height: 1rem;
  }
  /*.main-card {
    height: 100%;
  }*/
  /*.image-card-detail{
    height: 100%;
  }*/
  .subtitle {
    font-size: 11px;
  }
  .info-text {
    font-size: 9px;
    padding: 3px 0;
  }
}

@media screen and (min-width: 900px) {
  .subtitle {
    font-size: 0.7rem;
  }
  .grid-responsive{
    height: 100%;
  }
  /*.main-card {
    height: 70%;
  }*/
  /*.margin-responsive {
    margin-bottom: -12% !important;
  }*/
  .info-text {
    font-size: 0.6rem;
    line-height: 0.5rem;
  }
  .divider{
    display: flex;
  }
}

@media screen and (min-width: 969px) {
  /*.main-card {
    height: 65%;
  }*/
}

@media screen and (min-width: 1210px){
  /*.main-card {
    height: 50%;
  }*/
  /*.margin-responsive {
    margin-bottom: -22% !important;
  }*/
  .subtitle {
    font-size: 0.9rem;
  }
  .info-text {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
}