.image-card{
  height: 100%;
  border: 1px solid #5588A3;
  border-radius: "4px";
}

.box-style-info {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 5%;
  height: 65%;
  padding-bottom: 4px;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

@media screen and (min-width: 1200px) {
  .box-style-info{
    height: 71%;
  }
}